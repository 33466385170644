import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useLocation, useParams, useHistory } from 'react-router-dom';
import MainNav from '../Components/MainNav.js';
import { Footer1Button, Footer2Button } from '../Components/Footers';
import { Form, Formio } from '@formio/react';
import PopUpRegistrationSuccess from '../Components/PopUp.js';
import './../../components/formio/formio.css';
import { useRegistrationFormJson } from '../queries/hooks';
import { useDispatch, useSelector } from 'react-redux';
import DetailItemLoader from 'components/DetailItemLoader';
import { nextForm, previousForm } from 'registration/slices/registrationStepperSlice';
import { useMutateCartEvents, useMutateSubmitTournament } from '../queries/mutations';
import Loader from '../../components/Loader';
import { useQueryClient } from 'react-query';
import queryKeys from './../queries/queryKeys';
import {
  checkSchoolValidationBeforeSubmission,
  getPrefilledFormData,
} from 'registration/services/index.js';
import './registration.css';
Formio.icons = 'fontawesome';
let submitForm = null;
export default function Registration() {
  const { search } = useLocation();
  const scrollRef = useRef(null);
  const inviteId = new URLSearchParams(search).get('invite_id');
  const institution = new URLSearchParams(search).get('institution');
  const currentForm = useSelector((state) => state.modal.currentForm);
  // const next = useSelector((state) => state.modal.next);
  const previous = useSelector((state) => state.modal.previous);
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  let formioform;

  function setForm(formio) {
    formioform = formio;
  }
  const client = useQueryClient();
  const params = useParams();
  const tournamentId = params.tournament_id;
  const registrationFormJsonQueryData = useRegistrationFormJson({
    tournament_id: tournamentId,
    invite_id: inviteId || '',
  });
  // const cartItems = useCartItems()
  // const cartSummary = useCartSummary()
  // const cartOverview = useCartOverview()
  const { mutateAsync: mutateCart } = useMutateCartEvents({
    onSuccess: (_res) => {
      client.invalidateQueries(queryKeys.GET_CART_ITEMS);
      client.invalidateQueries(['registration', 'cart-summary']);
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });
  // case 1: call when no form is there.

  useEffect(() => {
    if (registrationFormJsonQueryData?.data?.error && params.user === 'coach') {
      return history.push(`/${params.user}/profiles/profile`);
    }
    if (
      registrationFormJsonQueryData.isFetched &&
      registrationFormJsonQueryData?.data?.forms?.length === 0
    ) {
      addEventHandler(registrationFormJsonQueryData?.data?.sport_event);
      if (
        registrationFormJsonQueryData.data &&
        !registrationFormJsonQueryData?.data?.error &&
        registrationFormJsonQueryData.data.forms &&
        registrationFormJsonQueryData.data.forms.length === 0
      ) {
        if (inviteId)
          return history.push(
            `/${params.user}/registration/${params.tournament_id}/payment?invite_id=${inviteId}`
          );
        if (params.user === 'coach') {
          return history.push(`/${params.user}/profiles/profile`);
        }
        return history.push(`/${params.user}/registration/${params.tournament_id}/sport-events`);
      }
    }
  }, [registrationFormJsonQueryData.isLoading]);

  const addEventHandler = (event) => {
    mutateCart({ sportEvent: event });
    setTimeout(() => {
      mutateCart({ sportEvent: event });
    }, 1000);
  };

  const { mutateAsync: submitTournamentAsync } = useMutateSubmitTournament({
    onError: () => {},
    onSuccess: () => {},
  });

  submitForm = async (submission, data) => {
    try {
      const values = {
        formTemplateName: data.forms[currentForm].form_template_name,
        formSubmission: submission,
        tournamentId,
        data: data,
      };

      if (inviteId && currentForm === 0) {
        await checkSchoolValidationBeforeSubmission(
          inviteId,
          submission?.data?.schoolName?.name,
          tournamentId
        );
        addEventHandler(registrationFormJsonQueryData?.data?.sport_event);
      }
      const res = submitTournamentAsync(values);
      formioform.setAlert('info', res.message);

      res.then((res) => {
        if (inviteId && currentForm === res.data.forms.length - 1) {
          history.push(
            `/${params.user}/registration/${params.tournament_id}/payment${
              inviteId ? `?invite_id=${inviteId}` : ''
            }`
          );
          return;
        }

        dispatch(
          nextForm({
            submission: nextForm(res.formSubmission),
            currentForm: res.data.forms[currentForm].form_template_name,
            forms: res.data.forms,
          })
        );
      });
      // next();
    } catch (e) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      formioform.setAlert('danger', e.message);
    }
  };

  let textMsg = '';
  if (params.user === 'institution')
    textMsg =
      "Your Institution's account has been created successfully. Kindly register your Institute for the SFA Championship Uttarakhand 2022 now.";
  else if (params.user === 'athlete')
    textMsg = 'Your account has been created successfully and you can register for the tournament.';
  else if (params.user === 'coach')
    textMsg =
      "Your account has been created successfully. You can register for tournament here but you'll only be able to create team after registration.";

  const redirectChecker = () => {
    if (registrationFormJsonQueryData.data && registrationFormJsonQueryData.data.forms) {
      if (currentForm >= registrationFormJsonQueryData.data.forms.length) {
        if (params.user === 'institution')
          return `/${params.user}/registration/${params.tournament_id}/sports-selection`;
        else if (!registrationFormJsonQueryData.data.sport_event && params.user === 'athlete')
          return `/${params.user}/registration/${params.tournament_id}/sport-events`;
        else if (!registrationFormJsonQueryData.data.sport_event && params.user === 'coach')
          return `/${params.user}/registration/${params.tournament_id}/registration-success`;
      }
    }
  };

  const toRedirect = registrationFormJsonQueryData.data && redirectChecker();
  if (toRedirect) {
    return <Redirect to={toRedirect} />;
  }

  return registrationFormJsonQueryData.isLoading ? (
    <Loader />
  ) : !registrationFormJsonQueryData?.data?.error ? (
    <div className="bg-white md:bg-gray-100 pb-24" ref={scrollRef}>
      <MainNav noBackButton={true} />
      {currentForm === 0 && <PopUpRegistrationSuccess text={textMsg} />}

      <div className="bg-white mx-0 md:mx-36 px-4 py-8 rounded-none md:rounded-xl border-0 md:border-1 border-gray-150">
        <p className="font-bold text-lg text-gray-750 mb-2">Tournament Registration Form</p>
        {registrationFormJsonQueryData?.data?.error ? (
          <div className="w-full flex flex-col justify-center items-center">
            <p className="py-8 text-center text-red-600">
              {registrationFormJsonQueryData.data.error}
            </p>
          </div>
        ) : (
          <DetailItemLoader queryData={registrationFormJsonQueryData}>
            {(props) => (
              <TournamentFormRenderer
                formReady={setForm}
                inviteId={inviteId}
                institution={institution}
                {...props}
              />
            )}
          </DetailItemLoader>
        )}
      </div>
      {previous === true && currentForm !== 0 ? (
        <Footer2Button
          showBack={true}
          onReset={() => formioform.resetValue()}
          onClick={() => {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            formioform.submit();
          }}
          onPrevious={() => dispatch(previousForm())}
        />
      ) : (
        <Footer1Button
          onReset={() => formioform.resetValue()}
          nextButtonText={
            !registrationFormJsonQueryData?.data?.error
              ? currentForm === registrationFormJsonQueryData?.data?.forms?.length - 1 &&
                params.user === 'coach'
                ? 'Submit'
                : false
              : 'Explore More Events'
          }
          onClick={
            !registrationFormJsonQueryData?.data?.error
              ? async () => {
                  try {
                    await formioform.submit();
                  } catch (e) {
                    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                }
              : () => {
                  params.user === 'athlete'
                    ? history.push(`/${params.user}/registration/${tournamentId}/sport-events`)
                    : params.user === 'coach'
                    ? history.push(
                        `/${params.user}/registration/${tournamentId}/registration-success`
                      )
                    : history.push(`/${params.user}/registration/${tournamentId}/sports-selection`);
                }
          }
        />
      )}
    </div>
  ) : (
    <div className="h-full flex flex-col items-center justify-center text-gray-750 font-semibold gap-4">
      {' '}
      {registrationFormJsonQueryData?.data?.error}
      <span className="text-gray-750 text-sm font-medium">
        {' '}
        <span
          className="border-b-1 border-gray-650 cursor-pointer leading-5"
          onClick={() => {
            history.push(`/${params.user}/profiles/profile`);
          }}
        >
          Click here
        </span>{' '}
        to View Your Profile
      </span>
    </div>
  );
}

const TournamentFormRenderer = React.forwardRef(({ data, ...props }, _ref) => {
  const params = useParams();
  const currentForm = useSelector((state) => state.modal.currentForm);
  const [load, setLoading] = useState(true);
  const [prefilledData, setPrefilledData] = useState({});
  useEffect(() => {
    const a = async () => {
      try {
        console.log(data.forms[currentForm]);
        const res = await getPrefilledFormData(
          data?.forms[currentForm]?.form_template_name,
          props.inviteId,
          props.institution
        );
        console.log(data.forms[currentForm]);

        if (res?.message?.institution_id) {
          setPrefilledData({
            message: {
              form_submission: {
                data: {
                  schoolName: {
                    name: res.message.institution_id,
                    institution: res.message.institution_name,
                  },
                },
              },
            },
          });
          console.log(data.forms);
        } else {
          setPrefilledData(res);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    a();
  }, []);

  if (data.forms.length === 1 && data.forms[0].is_paid)
    return (
      <Redirect
        to={`/${params.user}/registration/${params.tournament_id}/payment-success?alreadyPaid=true`}
      />
    );
  if (load) return <Loader />;

  return (
    <Form
      options={{
        disabled: {
          schoolName: props.inviteId || props.institution,
        },
      }}
      submission={prefilledData?.message?.form_submission}
      formReady={props.formReady}
      form={data.forms[currentForm]?.form || {}}
      onSubmit={(submission) => submitForm(submission, data)}
    />
  );
});

TournamentFormRenderer.displayName = 'TournamentFormRenderer';
